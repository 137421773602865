import React from "react"
import notFoundImage from "../assets/404.svg"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import LandingPageTemplate from "../templates/landing-page-template"
import TitleParagraphsTemplate from "../templates/title-paragraphs-template"
import AboutTemplate from "../templates/about-template"
import B2BHomepageTemplate from "../templates/b2b-homepage-template"
import B2BHomepageV2Template from "../templates/b2b-homepage-v2-template"
import HomepageTemplate from "../templates/homepage-template"
import BlogHomeTemplate from "../templates/blog-home-template"
import BlogPostTemplate from "../templates/blog-post-template"
import CategoryTemplate from "../templates/category-template"
import CandidatesTemplate from "../templates/candidates-template"
import CandidatesV2Template from "../templates/candidates-v2-template"
import PricingTemplate from "../templates/pricing-template"
import CustomTagTemplate from "../templates/custom-tag-template"
import RecruitersTemplate from "../templates/recruiters-template"
import RecruitersV2Template from "../templates/recruiters-v2-template"

const NotFoundPage = () => (
  <Layout mainPage="companies" locale="en-us" path="/404">
    <section className="bg-dark py-9 text-center text-md-left">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 text-white">
            <h1>Page not found.</h1>
            <p className="mb-4">
              Sorry, we cannot find the page you are looking for.
            </p>
            <p className="mb-6">
              <Link className="cta" to="/">
                Back home
              </Link>
            </p>
          </div>
          <div className="col-md-7">
            <img className="img-fluid" src={notFoundImage} width="100%" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    landing_page: LandingPageTemplate,
    prismicLandingPage: LandingPageTemplate,

    "title-paragraphs": TitleParagraphsTemplate,
    prismicTitleParagraphs: TitleParagraphsTemplate,

    about: AboutTemplate,
    prismicAbout: AboutTemplate,

    b2b_homepage: B2BHomepageTemplate,
    prismicB2BHomepage: B2BHomepageTemplate,

    homepage: HomepageTemplate,
    prismicHomepage: HomepageTemplate,

    b2b_homepage_v2: B2BHomepageV2Template,
    prismicB2BHomepageV2: B2BHomepageV2Template,

    blog_home: BlogHomeTemplate,
    prismicBlogHome: BlogHomeTemplate,

    blog_post: BlogPostTemplate,
    prismicBlogPost: BlogPostTemplate,

    candidates: CandidatesTemplate,
    prismicCandidates: CandidatesTemplate,

    candidates_v2: CandidatesV2Template,
    prismicCandidatesV2: CandidatesV2Template,

    category: CategoryTemplate,
    prismicCategory: CategoryTemplate,

    custom_tag: CustomTagTemplate,
    prismicCustomTag: CustomTagTemplate,

    pricing: PricingTemplate,
    prismicPricing: PricingTemplate,

    recruiters: RecruitersTemplate,
    prismicRecruiters: RecruitersTemplate,

    recruiters_v2: RecruitersV2Template,
    prismicRecruitersV2: RecruitersV2Template,
  },
})
